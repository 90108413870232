<template>
  <div>
    <breadCrumb title="秒杀活动" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="row row-cols-1 row-cols-lg-2 gutters-10">
          <div class="col" v-for="(item, i) in flashList" :key="i" @click="toDetail(item)">
            <div class="bg-white rounded shadow-sm mb-3">
              <a class="d-block text-reset">
                <img :src="item.url" class="img-fluid rounded w-100 lazyloaded">
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: '秒杀活动',
          path: 'flashDeal'
        },
      ],
      flashList: [
        {
          url: require('../../assets/imgs/flash.png'),
        },
        {
          url: require('../../assets/imgs/flash.png')
        },
      ]
     
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'flashDealDetail'
      })
    }
  }
}
</script>